import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteguarulhos.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3518800',
  schema: 'pbi_dte_guarulhos',
  groupId: '06ffc4e7-38f6-4866-90b9-2882c403eb86',
  analiseGeral: '56f3062f-524b-4620-a48d-e0ae23d43067',
  analiseUsuarios: '1193583e-ba80-4ec0-87ab-8eec878f7322',
  analiseComunicados: '25a8c634-a3b2-4fb5-94ff-0062ceaca2c3',
  analiseProcurador: '95953ee4-c7f5-48ef-bc25-63f4c97b83d4',
  analiseEventos: 'ee70559c-592b-47d1-99cb-bf507054cfc9',
  analiseContribuintes: 'aa4ae5c6-b6a5-4d86-858f-376988ff511d'
};
